import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import './Diagnosis.scss'

const supraspinatusTearOptions = [
  {value: "Partial thickness", label: "Partial thickness"},
  {value: "Articular ", label: "Articular "},
  {value: "Bursal", label: "Bursal"},
  {value: "> 50% thickness", label: "> 50% thickness"},
  {value: "Full thickness", label: "Full thickness"},
  {value: "Retracted", label: "Retracted"},
  {value: "Leading edge detached", label: "Leading edge detached"},
  {value: "Delaminated tear", label: "Delaminated tear"},
]

const infraspinatusTearOptions = [
  {value: "Partial thickness", label: "Partial thickness"},
  {value: "Articular ", label: "Articular "},
  {value: "Bursal", label: "Bursal"},
  {value: "> 50% thickness", label: "> 50% thickness"},
  {value: "Full thickness", label: "Full thickness"},
  {value: "Retracted", label: "Retracted"},
  {value: "Delaminated tear", label: "Delaminated tear"},
]

const teresMinorTearOptions = [
  {value: "Partial thickness", label: "Partial thickness"},
  {value: ">50% thickness", label: ">50% thickness"},
  {value: "Full thickness", label: "Full thickness"},
  {value: "Retracted", label: "Retracted"},
]

const directionOfInstabilityOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Multidirectional", label: "Multidirectional"},
]

const labralTearAnteriorOptions = [
  {value: "Bankart", label: "Bankart"},
  {value: "Bony Bankart", label: "Bony Bankart"},
  {value: "ALPSA Lesion", label: "ALPSA Lesion"},
]

const labralTearPosteriorOptions = [
  {value: "Kim Lesion", label: "Kim Lesion"},
  {value: "Bankart", label: "Bankart"},
  {value: "Bony Bankart", label: "Bony Bankart"},
]

const glenoidBoneDefectOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "< 10%", label: "< 10%"},
  {value: "10-25%", label: "10-25%"},
  {value: "> 25%", label: "> 25%"},
]

const humeralHeadHillsSachsLesionOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Engaging (in 90-90 Position)", label: "Engaging (in 90-90 Position)"}
]

const conditionOptions = [
  {value: "Flat/Deformed", label: "Flat/Deformed"},
  {value: "Subluxed", label: "Subluxed"},
  {value: "Inflamed", label: "Inflamed"}
]

const typeOptions = [
  {value: "Pre-acromion", label: "Pre-acromion"},
  {value: "Meso-acromion", label: "Meso-acromion"},
  {value: "Meta-acromion", label: "Meta-acromion"}
]

const probableCauseOptions = [
  {value: "Traumatic cartilage lesion", label: "Traumatic cartilage lesion"},
  {value: "Osteochondritis Dissecans (OCD)", label: "Osteochondritis Dissecans (OCD)"},
  {value: "OA (primary osteoarthritis) ", label: "OA (primary osteoarthritis) "},
  {value: "AVN", label: "AVN"},
  {value: "Other", label: "Other"},
]

const proximalHumerusOptions = [
  {value: "Two part", label: "Two part"},
  {value: "Three part", label: "Three part"},
  {value: "Four part", label: "Four part"},
  {value: "Greater tuberosity", label: "Greater tuberosity"},
  {value: "Lesser tuberosity", label: "Lesser tuberosity"},
  {value: "Surgical neck", label: "Surgical neck"},
  {value: "Anatomic neck", label: "Anatomic neck"},
]

const pecMajorRuptureOptions = [
  {value: "Chronic (> 6 weeks)", label: "Chronic (> 6 weeks)"},
  {value: "One head", label: "One head"},
  {value: "Both heads", label: "Both heads"},
  {value: "Retracted", label: "Retracted"},
]

const romAbnormalityOptions = [
  {value: "Adhesive Capsulitis", label: "Adhesive Capsulitis"},
  {value: "Arthrofibrosis (Post-Surgical)", label: "Arthrofibrosis (Post-Surgical)"},
  {value: "Arthrofibrosis ", label: "Arthrofibrosis "},
  {value: "GIRD Present", label: "GIRD Present"},
  {value: "Increased ER", label: "Increased ER"},
]

const scJointOptions = [
  {value: "Dislocation", label: "Dislocation"},
  {value: "Subluxation/Instability", label: "Subluxation/Instability"},
  {value: "Arthritis-Degenerative", label: "Arthritis-Degenerative"},
  {value: "Arthritis - Septic", label: "Arthritis - Septic"},
  {value: "Physeal fracture", label: "Physeal fracture"},
]

const arthritisTypeOptions = [
  {value: "Degenerative osteoarthritis", label: "Degenerative osteoarthritis"},
  {value: "Post-traumatic arthritis", label: "Post-traumatic arthritis"},
  {value: "Rheumatoid arthritis", label: "Rheumatoid arthritis"},
  {value: "Post-surgical arthritis", label: "Post-surgical arthritis"},
  {value: "Other inflammatory arthritis", label: "Other inflammatory arthritis"},
]

const indicationForRevisionOptions = [
  {value: "Pain", label: "Pain"},
  {value: "Rotator Cuff tear", label: "Rotator Cuff tear"},
  {value: "Instability", label: "Instability"},
  {value: "Infection", label: "Infection"},
  {value: "Nerve injury", label: "Nerve injury"},
  {value: "Loose component", label: "Loose component"},
  {value: "Other", label: "Other"},
]

const scapulaFractureTypeOptions = [
  {value: "Acromion", label: "Acromion"},
  {value: "Glenoid", label: "Glenoid"},
  {value: "Coracoid", label: "Coracoid"},
  {value: "Body", label: "Body"}
]

const tendonOtherOptions = [
  {value: "Snapping triceps syndrome", label: "Snapping triceps syndrome"},
  {value: "Flexor-pronator strain", label: "Flexor-pronator strain"},
]

const typeOfLigamentInjuryOptions = [
  {value: "Grade I strain", label: "Grade I strain"},
  {value: "Partial rupture", label: "Partial rupture"},
  {value: "Complete rupture", label: "Complete rupture"},
  {value: "Proximal bony avulsion", label: "Proximal bony avulsion"},
  {value: "Distal bony avulsion", label: "Distal bony avulsion"},
  {value: "Annular", label: "Annular"},
]

const ligamentInjuryOtherDiagnosisOptions = [
  {value: "Posterior Lateral Rotatory Instability (PLRI)", label: "Posterior Lateral Rotatory Instability (PLRI)"},
  {value: "Posteromedial Impingement", label: "Posteromedial Impingement"},
  {value: "Elbow dislocation", label: "Elbow dislocation"},
  {value: "Valgus overload syndrome", label: "Valgus overload syndrome"},
  {value: "Posteriomedial olecranon spur", label: "Posteriomedial olecranon spur"},
]

const osteochondralLesionProbableCauseOptions = [
  {value: "Traumatic cartilage lesion", label: "Traumatic cartilage lesion"},
  {value: "Osteochondritis Dissecans (OCD)", label: "Osteochondritis Dissecans (OCD)"},
  {value: "OA", label: "OA"},
  {value: "AVN", label: "AVN"},
  {value: "Other", label: "Other"},
]

const anteriorPosteriosOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
]

const capsularContractureOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Rotation", label: "Rotation"},
]

const synovitisOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Lateral", label: "Lateral"},
  {value: "Generalized", label: "Generalized"},
]

const intraArticularFractureOptions = [
  {value: "Radial head", label: "Radial head"},
  {value: "Capitellum", label: "Capitellum"},
  {value: "Trochlea", label: "Trochlea"},
  {value: "Coronoid", label: "Coronoid"},
  {value: "Olecranon", label: "Olecranon"},
]

const avulsionFractureOptions = [
  {value: "Medial epicondylar", label: "Medial epicondylar"},
  {value: "Lateral epicondylar", label: "Lateral epicondylar"},
]

const stressFractureOptions = [
  {value: "Olecranon", label: "Olecranon"},
  {value: "Distal Humerus", label: "Distal Humerus"},
]

const apophysealInjuryOptions = [
  {value: "Medial epicondyle", label: "Medial epicondyle"},
  {value: "Olecranon", label: "Olecranon"},
  {value: "Delayed union", label: "Delayed union"},
  {value: "Avulsion", label: "Avulsion"},
]

const olecranonSpurLocationOptions = [
  {value: "Anterior", label: "Anterior"}, 
  {value: "Posterior", label: "Posterior"},
  {value: "Medial", label: "Medial"}, 
  {value: "Lateral", label: "Lateral"},
]

const Diagnosis = ({form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "rotatorCuff": {
      return (
        <div className="diagnosis-rotator-cuff">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Acute or Chronic</Form.Label>
                      <Form.Select
                        value={formData.acuteOrChronic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acuteOrChronic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Acute">Acute</option>
                        <option value="Chronic">Chronic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of Tendons Torn</Form.Label>
                      <Form.Select
                        value={formData.numberOfTendonsTorn}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfTendonsTorn", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="One">One</option>
                        <option value="Two">Two</option>
                        <option value="Three">Three</option>
                        <option value="Four">Four</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.calcificTendonosis}
                        label="Calcific Tendonosis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "calcificTendonosis", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Rotator Cuff Tendon Quality</Form.Label>
                      <Form.Select
                        value={formData.rotatorCuffTendonQuality}
                        onChange={(e) => {onChangeHandler(arrayIndex, "rotatorCuffTendonQuality", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Poor">Poor</option>
                        <option value="Fair">Fair</option>
                        <option value="Good">Good</option>
                        <option value="Excellent">Excellent</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Snyder Classification - Complete tear size and retraction</Form.Label>
                      <Form.Select
                        value={formData.snyderClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "snyderClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="C1 -Small complete tear, i.e. pinhole sized">C1 -Small complete tear, i.e. pinhole sized</option>
                        <option value="C2 - Moderate tear <2 cm in any direction of only one tendon without retraction">C2 - Moderate tear &lt;2 cm in any direction of only one tendon without retraction</option>
                        <option value="C3 - Large, complete tear with an entire tendon with retraction, usually 3-4 cm in any direction">C3 - Large, complete tear with an entire tendon with retraction, usually 3-4 cm in any direction</option>
                        <option value="C4 - Massive rotator cuff tear invovling 2 or more rotator cuff tendons with associated retraction and scarring of the remaining tendon">C4 - Massive rotator cuff tear invovling 2 or more rotator cuff tendons with associated retraction and scarring of the remaining tendon</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <Form.Label>Cofield Classification of Tear Size</Form.Label>
                        <Form.Select
                          value={formData.cofieldClassification}
                          onChange={(e) => {onChangeHandler(arrayIndex, "cofieldClassification", e.target.value)}}
                        >
                          <option value="" hidden>Select an option</option>
                          <option value="Small (< 1 cm)">Small (&lt;1 cm)</option>
                          <option value="Medium (1-3 cm)">Medium (1-3 cm)</option>
                          <option value="Large (3-5 cm)">Large (3-5 cm)</option>
                          <option value="Massive (> 5 cm)">Massive (&gt;5 cm)</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Rotator Cuff Retraction Stage (Location of Lateral Edge of Tendon)</Form.Label>
                        <Form.Select
                          value={formData.rotatorCuffRetractionStage}
                          onChange={(e) => {onChangeHandler(arrayIndex, "rotatorCuffRetractionStage", e.target.value)}}
                        >
                          <option value="" hidden>Select an option</option>
                          <option value="Stage I">Stage I</option>
                          <option value="Stage II">Stage II</option>
                          <option value="Stage III">Stage III</option>
                          <option value="Stage IV">Stage IV</option>
                        </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Retraction Dimensions (Anterior to Posterior)</Form.Label>
                      <Form.Select
                        value={formData.rotatorDimensionsAnteriorToPosterior}
                        onChange={(e) => {onChangeHandler(arrayIndex, "rotatorDimensionsAnteriorToPosterior", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0-1">0-1</option>
                        <option value="1-3">1-3</option>
                        <option value="3-5">3-5</option>
                        <option value=">5">&gt;5</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Tear Size (cm Anterior to Posterior)</Form.Label>
                      <Form.Select
                        value={formData.tearSizeAnteriorToPosterior}
                        onChange={(e) => {onChangeHandler(arrayIndex, "tearSizeAnteriorToPosterior", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="<1">&lt;1</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Tear Size (cm Medial to Lateral)</Form.Label>
                      <Form.Select
                        value={formData.tearSizeMedialToLateral}
                        onChange={(e) => {onChangeHandler(arrayIndex, "tearSizeMedialToLateral", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="<1">&lt;1</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Rotator Cuff Tear Shape</Form.Label>
                      <Form.Select
                        value={formData.rotatorCuffTearShape}
                        onChange={(e) => {onChangeHandler(arrayIndex, "rotatorCuffTearShape", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="L-Shaped Anterior">L-Shaped Anterior</option>
                        <option value="L-Shaped Posterior">L-Shaped Posterior</option>
                        <option value="U-Shaped">U-Shaped</option>
                        <option value="Avulsion/Cresent">Avulsion/Cresent</option>
                        <option value="Massive Contracted">Massive Contracted</option>
                        <option value="Longitudinal">Longitudinal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Supraspinatus Tear</Form.Label>
                      <Select
                        isMulti
                        value={supraspinatusTearOptions.filter(option => formData.supraspinatusTear.split(",").includes(option.value))}
                        name="supraspinatusTear"
                        options={supraspinatusTearOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "supraspinatusTear", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Goutallier Stage - Supraspinatus  (CT Fatty Infiltration)</Form.Label>
                      <Form.Select
                        value={formData.goutallierStageSupraspinatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "goutallierStageSupraspinatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Stage 0">Stage 0</option>
                        <option value="Stage 1">Stage 1</option>
                        <option value="Stage 2">Stage 2</option>
                        <option value="Stage 3">Stage 3</option>
                        <option value="Stage 4">Stage 4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Infraspinatus Tear</Form.Label>
                      <Select
                        isMulti
                        value={infraspinatusTearOptions.filter(option => formData.infraspinatusTear.split(",").includes(option.value))}
                        name="infraspinatusTear"
                        options={infraspinatusTearOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "infraspinatusTear", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Goutallier Stage - Infraspinatus  (CT Fatty Infiltration)</Form.Label>
                      <Form.Select
                        value={formData.goutallierStageInfraspinatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "goutallierStageInfraspinatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Stage 0">Stage 0</option>
                        <option value="Stage 1">Stage 1</option>
                        <option value="Stage 2">Stage 2</option>
                        <option value="Stage 3">Stage 3</option>
                        <option value="Stage 4">Stage 4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Subscapularis Tear - LaFosse Classification</Form.Label>
                      <Form.Select
                        value={formData.laFosseClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "laFosseClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type I">Type I</option>
                        <option value="Type II">Type II</option>
                        <option value="Type III">Type III</option>
                        <option value="Type IV">Type IV</option>
                        <option value="Type V">Type V</option>
                        <option value="SAM lesion">SAM lesion</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Goutallier Stage - Subscapularis (CT Fatty Infiltration)</Form.Label>
                      <Form.Select
                        value={formData.goutallierStageSubScapularis}
                        onChange={(e) => {onChangeHandler(arrayIndex, "goutallierStageSubScapularis", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Stage 0">Stage 0</option>
                        <option value="Stage 1">Stage 1</option>
                        <option value="Stage 2">Stage 2</option>
                        <option value="Stage 3">Stage 3</option>
                        <option value="Stage 4">Stage 4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Teres Minor tear</Form.Label>
                      <Select
                        isMulti
                        value={teresMinorTearOptions.filter(option => formData.teresMinorTear.split(",").includes(option.value))}
                        name="teresMinorTear"
                        options={teresMinorTearOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "teresMinorTear", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Goutallier Stage - Teres Minor  (CT Fatty Infiltration)</Form.Label>
                      <Form.Select
                        value={formData.goutallierStageTeresMinor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "goutallierStageTeresMinor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Stage 0">Stage 0</option>
                        <option value="Stage 1">Stage 1</option>
                        <option value="Stage 2">Stage 2</option>
                        <option value="Stage 3">Stage 3</option>
                        <option value="Stage 4">Stage 4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subacromialImpingement" : {
      return (
        <div className="diagnosis-subacromial-impingement">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subacromial Impingement
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Acromion Type</Form.Label>
                      <Form.Select
                        value={formData.acromionType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acromionType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type I - flat">Type I - flat</option>
                        <option value="Type II - curved">Type II - curved</option>
                        <option value="Type III - hooked">Type III - hooked</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subcoracoidImpingement": {
      return (
        <div className="diagnosis-other">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subcoracoid Impingement
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                No further details
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "labralInjuryAndInstability" : {
      return (
        <div className="diagnosis-labral-injury-and-instability">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Labral Injury And Instability
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Direction of Instability</Form.Label>
                      <Select
                        isMulti
                        value={directionOfInstabilityOptions.filter(option => formData.directionOfInstability.split(",").includes(option.value))}
                        name="directionOfInstability"
                        options={directionOfInstabilityOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "directionOfInstability", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Glenohumeral Dislocation</Form.Label>
                      <Form.Select
                        value={formData.glenoHumeralDislocation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoHumeralDislocation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="First Time Dislocator">First Time Dislocator</option>
                        <option value="Second Time Dislocator">Second Time Dislocator</option>
                        <option value="Multiple Dislocations">Multiple Dislocations</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>SLAP lesion</Form.Label>
                      <Form.Select
                        value={formData.slapLesion}
                        onChange={(e) => {onChangeHandler(arrayIndex, "slapLesion", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="I (fraying)">I (fraying)</option>
                        <option value="II (unstable biceps anchor)">II (unstable biceps anchor)</option>
                        <option value="III (bucket handle - labrum only)">III (bucket handle - labrum only)</option>
                        <option value="IV (bucket handle - involving biceps)">IV (bucket handle - involving biceps)</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Labral tear - Anterior</Form.Label>
                      <Select
                        isMulti
                        value={labralTearAnteriorOptions.filter(option => formData.labralTearAnterior.split(",").includes(option.value))}
                        name="labralTearAnterior"
                        options={labralTearAnteriorOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "labralTearAnterior", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Labral tear - Posterior</Form.Label>
                      <Select
                        isMulti
                        value={labralTearPosteriorOptions.filter(option => formData.labralTearPosterior.split(",").includes(option.value))}
                        name="labralTearPosterior"
                        options={labralTearPosteriorOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "labralTearPosterior", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Bone Defect</Form.Label>
                      <Select
                        isMulti
                        value={glenoidBoneDefectOptions.filter(option => formData.glenoidBoneDefect.split(",").includes(option.value))}
                        name="glenoidBoneDefect"
                        options={glenoidBoneDefectOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "glenoidBoneDefect", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Head (Hills Sachs Lesion)</Form.Label>
                      <Select
                        isMulti
                        value={humeralHeadHillsSachsLesionOptions.filter(option => formData.humeralHeadHillsSachsLesion.split(",").includes(option.value))}
                        name="humeralHeadHillsSachsLesion"
                        options={humeralHeadHillsSachsLesionOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "humeralHeadHillsSachsLesion", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>HAGL</Form.Label>
                      <Form.Select
                        value={formData.hagl}
                        onChange={(e) => {onChangeHandler(arrayIndex, "hagl", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Anterior">Anterior</option>
                        <option value="Posterior">Posterior</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.alpsa}
                        label="ALPSA" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "alpsa", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.glenoHumeralChondropathy}
                        label="Glenohumeral Chondropathy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoHumeralChondropathy", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "biceps" : {
      return (
        <div className="diagnosis-biceps">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Biceps
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Degree of Tear</Form.Label>
                      <Form.Select
                        value={formData.degreeOfTear}
                        onChange={(e) => {onChangeHandler(arrayIndex, "degreeOfTear", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Full Thickness (Retracted)">Full Thickness (Retracted)</option>
                        <option value="Partial tear <50%">Partial tear &lt;50%</option>
                        <option value="Partial tear >50%">Partial tear &gt;50%</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Condition</Form.Label>
                      <Select
                        isMulti
                        value={conditionOptions.filter(option => formData.condition.split(",").includes(option.value))}
                        name="condition"
                        options={conditionOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "condition", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "acJoint" : {
      return (
        <div className="diagnosis-ac-joint">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AC Joint
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Acute or Chronic</Form.Label>
                      <Form.Select
                        value={formData.acuteOrChronic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acuteOrChronic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Acute">Acute</option>
                        <option value="Chronic">Chronic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>AC Instability (Type)</Form.Label>
                      <Form.Select
                        value={formData.acInstabilityType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acInstabilityType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type I">Type I</option>
                        <option value="Type II">Type II</option>
                        <option value="Type III">Type III</option>
                        <option value="Type IV">Type IV</option>
                        <option value="Type V">Type V</option>
                        <option value="Type VI">Type VI</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osAcromiale" : {
      return (
        <div className="diagnosis-os-acromiale">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Os Acromiale
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={typeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={typeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "glenohumeralArthritis" : {
      return (
        <div className="diagnosis-glenohumeral-arthritis">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Glenohumeral Arthritis
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid (Outerbridge grade)</Form.Label>
                      <Form.Select
                        value={formData.glenoidOuterbridgeGrade}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidOuterbridgeGrade", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="(1-3)">(1-3)</option>
                        <option value="4">4</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humerus (Outerbridge grade)</Form.Label>
                      <Form.Select
                        value={formData.humerusOuterbridgeGrade}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humerusOuterbridgeGrade", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="(1-3)">(1-3)</option>
                        <option value="4">4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Kellgren-Lawrence Classification</Form.Label>
                      <Form.Select
                        value={formData.kellgrenLawrenceClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "kellgrenLawrenceClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "articularCartilageOsteochondralLesion" : {
      return (
        <div className="diagnosis-articluar-cartilage-osteochondral-lesion">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Articular Cartilage Osteochondral Leason
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenoid">Glenoid</option>
                        <option value="Humeral Head">Humeral Head</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.fullThicknessCartilageDefectWithWellDefinedBorders}
                        label="Full thickness cartilage defect with well defined borders" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "fullThicknessCartilageDefectWithWellDefinedBorders", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Probable cause</Form.Label>
                      <Select
                        isMulti
                        value={probableCauseOptions.filter(option => formData.probableCause.split(",").includes(option.value))}
                        name="probableCause"
                        options={probableCauseOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "probableCause", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>ICRS Grade</Form.Label>
                      <Form.Select
                        value={formData.icrsGrade}
                        onChange={(e) => {onChangeHandler(arrayIndex, "icrsGrade", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Grade 1A">Grade 1A</option>
                        <option value="Grade 1B">Grade 1B</option>
                        <option value="Grade 2">Grade 2</option>
                        <option value="Grade 3A">Grade 3A</option>
                        <option value="Grade 3B">Grade 3B</option>
                        <option value="Grade 3C">Grade 3C</option>
                        <option value="Grade 3D">Grade 3D</option>
                        <option value="Grade 4A">Grade 4A</option>
                        <option value="Grade 4B">Grade 4B</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Length of Defect</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Width of Defect</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Area of Defect</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.area}
                        onChange={(e) => {onChangeHandler(arrayIndex, "area", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Depth of Defect</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.depth}
                        onChange={(e) => {onChangeHandler(arrayIndex, "depth", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "otherIntraArticular" : {
      return (
        <div className="diagnosis-other-intra-articular">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Other Intra Articular
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.synovialDisease}
                        label="Synovial disease" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "synovialDisease", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.synovialCyst}
                        label="Synovial cyst" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "synovialCyst", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.looseBodyGreaterThan1Cm}
                        label="Loose body > 1 cm"
                        onChange={(e) => {onChangeHandler(arrayIndex, "looseBodyGreaterThan1Cm", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "fracture" : {
      return (
        <div className="diagnosis-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Clavicle Distal Third (Neer's classification)</Form.Label>
                      <Form.Select
                        value={formData.clavicleDistalThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleDistalThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type I">Type I (minimally displaced/interligamentous)</option>
                        <option value="Type IIA">Type IIA (both conoid and trapezoid remain attached to distal fragment)</option>
                        <option value="Type IIB">Type IIB (either the conoid is torn or both the conoid and trapezoid are torn)</option>
                        <option value="Type III (fractures involving articular surface)">Type III (fractures involving articular surface)</option>
                        <option value="Type IV">Type IV (ligaments intact to the periosteum with displacement of the proximal fragment)</option>
                        <option value="Type V">Type V (comminuted)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Clavicle - Middle Third</Form.Label>
                      <Form.Select
                        value={formData.clavicleMiddleThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleMiddleThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Non-displaced">Non-displaced</option>
                        <option value="<50% displaced">&lt;50% displaced</option>
                        <option value=">50% displaced">&gt;50% displaced</option>
                        <option value="Comminuted">Comminuted</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Clavicle - Proximal Third</Form.Label>
                      <Form.Select
                        value={formData.clavicleProximalThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleProximalThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Non-displaced">Non-displaced</option>
                        <option value="<50% displaced">&lt;50% displaced</option>
                        <option value=">50% displaced">&gt;50% displaced</option>
                        <option value="Comminuted">Comminuted</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Proximal humerus</Form.Label>
                      <Select
                        isMulti
                        value={proximalHumerusOptions.filter(option => formData.proximalHumerus.split(",").includes(option.value))}
                        name="proximalHumerus"
                        options={proximalHumerusOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "proximalHumerus", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.acromion}
                        label="Acromion" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "acromion", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.coracoid}
                        label="Coracoid" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "coracoid", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.scapularBody}
                        label="Scapular body" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "scapularBody", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid</Form.Label>
                      <Form.Select
                        value={formData.glenoid}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoid", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Anterior rim">Anterior rim</option>
                        <option value="Posterior rim">Posterior rim</option>
                        <option value="Rim - other location">Rim - other location</option>
                        <option value="Neck">Neck</option>
                        <option value="Other location">Other location</option>
                        <option value="Surgical neck">Surgical neck</option>
                        <option value="Anatomic neck">Anatomic neck</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Fracture Status</Form.Label>
                      <Form.Select
                        value={formData.fractureStatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "fractureStatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh fracture">Fresh fracture</option>
                        <option value="Malunion">Malunion</option>
                        <option value="Nonunion">Nonunion</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "miscellaneous" : {
      return (
        <div className="diagnosis-miscellaneous">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Miscellaneous
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.hematoma}
                        label="Hematoma" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "hematoma", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.infection}
                        label="Infection" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "infection", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.fattyAtrophy}
                        label="Fatty atrophy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "fattyAtrophy", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>SC Joint</Form.Label>
                      <Select
                        isMulti
                        value={scJointOptions.filter(option => formData.scJoint.split(",").includes(option.value))}
                        name="scJoint"
                        options={scJointOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "scJoint", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Pec Major Rupture</Form.Label>
                      <Select
                        isMulti
                        value={pecMajorRuptureOptions.filter(option => formData.pecMajorRupture.split(",").includes(option.value))}
                        name="pecMajorRupture"
                        options={pecMajorRuptureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "pecMajorRupture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>ROM abnormalities</Form.Label>
                      <Select
                        isMulti
                        value={romAbnormalityOptions.filter(option => formData.romAbnormalities.split(",").includes(option.value))}
                        name="romAbnormalities"
                        options={romAbnormalityOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "romAbnormalities", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.suprascapularNerveCompression}
                        label="Suprascapular nerve compression" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "suprascapularNerveCompression", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.subacromialBursitis}
                        label="Subacromial Bursitis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "subacromialBursitis", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.scapulothoracicBursitis}
                        label="Scapulothoracic Bursitis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "scapulothoracicBursitis", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.woundProblem}
                        label="Wound problem" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "woundProblem", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "arthritis": {
      return (
        <div className="diagnosis-arthritis">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Arthritis
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={arthritisTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="procedures"
                        options={arthritisTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "rotatorCuffArthropathy": {
      return (
        <div className="diagnosis-rotator-cuff-arthropathy">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff Arthropathy
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Subscap</Form.Label>
                      <Form.Select
                        value={formData.subscap}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscap", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Repairable">Repairable</option>
                        <option value="Unrepairable">Unrepairable</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "failedRotatorCuffRepair": {
      return (
        <div className="diagnosis-failed-rotator-cuff-repair">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Failed Rotator Cuff Repair
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Subscap</Form.Label>
                      <Form.Select
                        value={formData.subscap}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscap", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Repairable">Repairable</option>
                        <option value="Unrepairable">Unrepairable</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "failedShoulderArthroplasty": {
      return (
        <div className="diagnosis-failed-shoulder-arthroplasty">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Failed Shoulder Arthroplasty
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Hemiarthroplasty">Hemiarthroplasty</option>
                        <option value="Humeral head resurfacing">Humeral head resurfacing</option>
                        <option value="Biologic total shoulder resurfacing">Biologic total shoulder resurfacing</option>
                        <option value="Total shoulder replacement">Total shoulder replacement</option>
                        <option value="Inverse/Reverse total shoulder replacement">Inverse/Reverse total shoulder replacement</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Size of focal humeral head resurfacing implant</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={500}
                        value={formData.sizeOfFocalHumeralHeadResurfacingImplant}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sizeOfFocalHumeralHeadResurfacingImplant", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Indications for revision</Form.Label>
                      <Select
                        isMulti
                        value={indicationForRevisionOptions.filter(option => formData.indicationForRevision.split(",").includes(option.value))}
                        name="procedures"
                        options={indicationForRevisionOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "indicationForRevision", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Subscap</Form.Label>
                      <Form.Select
                        value={formData.subscap}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscap", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Repairable">Repairable</option>
                        <option value="Unrepairable">Unrepairable</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "clavicleFracture": {
      return (
        <div className="diagnosis-clavicle-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Clavicle Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Distal third (Neer's classification)</Form.Label>
                      <Form.Select
                        value={formData.clavicleDistalThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleDistalThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type I">Type I</option>
                        <option value="Type IIA">Type IIA</option>
                        <option value="Type IIB">Type IIB</option>
                        <option value="Type III">Type III</option>
                        <option value="Type IV">Type IV</option>
                        <option value="Type V">Type V</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Middle third</Form.Label>
                      <Form.Select
                        value={formData.clavicleMiddleThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleMiddleThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Non-displaced">Non-displaced</option>
                        <option value="< 50% displaced">&lt; 50% displaced</option>
                        <option value="> 50% displaced">&gt; 50% displaced</option>
                        <option value="Comminuted">Comminuted</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Proximal thrid</Form.Label>
                      <Form.Select
                        value={formData.clavicleProximalThird}
                        onChange={(e) => {onChangeHandler(arrayIndex, "clavicleProximalThird", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Non-displaced">Non-displaced</option>
                        <option value="< 50% displaced">&lt; 50% displaced</option>
                        <option value="> 50% displaced">&gt; 50% displaced</option>
                        <option value="Comminuted">Comminuted</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Fracture Status</Form.Label>
                      <Form.Select
                        value={formData.fractureStatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "fractureStatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh fracture">Fresh fracture</option>
                        <option value="Malunion">Malunion</option>
                        <option value="Nonunion">Nonunion</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "humeralFracture": {
      return (
        <div className="diagnosis-humeral-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Humeral Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="2 part">2 part</option>
                        <option value="3 part">3 part</option>
                        <option value="4 part">4 part</option>
                        <option value="peri-prosthetic">peri-prosthetic</option>
                        <option value="Humeral shaft">Humeral shaft</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.acute}
                        label="Acute" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "acute", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.priorFractureSequelae}
                        label="Prior fracture sequelae" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "priorFractureSequelae", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Fracture Status</Form.Label>
                      <Form.Select
                        value={formData.fractureStatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "fractureStatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh fracture">Fresh fracture</option>
                        <option value="Malunion">Malunion</option>
                        <option value="Nonunion">Nonunion</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "scapulaFracture": {
      return (
        <div className="diagnosis-scapula-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Scapula Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={scapulaFractureTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={scapulaFractureTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Fracture Status</Form.Label>
                      <Form.Select
                        value={formData.fractureStatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "fractureStatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh fracture">Fresh fracture</option>
                        <option value="Malunion">Malunion</option>
                        <option value="Nonunion">Nonunion</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "chronicRotatorCuffTear": {
      return (
        <div className="diagnosis-chronic-rotator-cuff-tear">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Chronic Rotator Cuff Tear
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Subscap</Form.Label>
                      <Form.Select
                        value={formData.subscap}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscap", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Repairable">Repairable</option>
                        <option value="Unrepairable">Unrepairable</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Hamada Classification</Form.Label>
                      <Form.Select
                        value={formData.hamadaClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "hamadaClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Grade I = Acromial humeral distance ≥ 6mm">Grade I = Acromial humeral distance ≥ 6mm</option>
                        <option value="Grade II = Acromial humeral distance ≤ 5mm">Grade II = Acromial humeral distance ≤ 5mm</option>
                        <option value="Grade III = Grade II plus acetabularization of the acromion">Grade III = Grade II plus acetabularization of the acromion</option>
                        <option value="Grade IV = Grade III plus narrowing of the gleno-humeral joint ">Grade IV = Grade III plus narrowing of the gleno-humeral joint </option>
                        <option value="Grade V = Grade IV plus humeral head collapse">Grade V = Grade IV plus humeral head collapse</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "avn": {
      return (
        <div className="diagnosis-other">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AVN
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.avascularNecrosisHumeralHead}
                        label="Avascular necrosis humeral head" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "avascularNecrosisHumeralHead", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.humeralHeadCollapsed}
                        label="Humeral head collapsed" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralHeadCollapsed", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.normalGlenoidCartilage}
                        label="Normal glenoid cartilage" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "normalGlenoidCartilage", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "tendon": {
      return (
        <div className="diagnosis-tendon">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Tendon
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Tendon</Form.Label>
                      <Form.Select
                        value={formData.tendon}
                        onChange={(e) => {onChangeHandler(arrayIndex, "tendon", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Tennis elbow (Lateral tendons)">Tennis elbow (Lateral tendons)</option>
                        <option value="Golfer's elbow (Medial tendons)">Golfer's elbow (Medial tendons)</option>
                        <option value="Biceps tendon">Biceps tendon</option>
                        <option value="Triceps tendon">Triceps tendon</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Acute or Chronic</Form.Label>
                      <Form.Select
                        value={formData.acuteOrChronic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acuteOrChronic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Acute (<2 weeks)">Acute (&lt;2 weeks)</option>
                        <option value="Subacute (2-6 weeks)">Subacute (2-6 weeks)</option>
                        <option value="Chronic (>6 weeks)">Chronic (&gt;6 weeks)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type of tendon injury</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Tendinitis">Tendinitis</option>
                        <option value="Tendonosis">Tendonosis</option>
                        <option value="Partial rupture">Partial rupture</option>
                        <option value="Complete rupture">Complete rupture</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Other</Form.Label>
                      <Select
                        isMulti
                        value={tendonOtherOptions.filter(option => formData.other.split(",").includes(option.value))}
                        name="other"
                        options={tendonOtherOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "other", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowLigamentInstability": {
      return (
        <div className="diagnosis-ligament-instability">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Ligament/Instability
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Ligament</Form.Label>
                      <Form.Select
                        value={formData.ligament}
                        onChange={(e) => {onChangeHandler(arrayIndex, "ligament", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Ulnar collateral ">Ulnar collateral </option>
                        <option value="Radial collateral">Radial collateral</option>
                        <option value="Lateral collateral">Lateral collateral</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Acute or Chronic</Form.Label>
                      <Form.Select
                        value={formData.acuteOrChronic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acuteOrChronic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Acute (<2 weeks)">Acute (&lt;2 weeks)</option>
                        <option value="Subacute (2-6 weeks)">Subacute (2-6 weeks)</option>
                        <option value="Chronic (>6 weeks)">Chronic (&gt;6 weeks)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type of ligament injury</Form.Label>
                      <Select
                        isMulti
                        value={typeOfLigamentInjuryOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={typeOfLigamentInjuryOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Instability direction</Form.Label>
                      <Form.Select
                        value={formData.directionOfInstability}
                        onChange={(e) => {onChangeHandler(arrayIndex, "directionOfInstability", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Anterior">Anterior</option>
                        <option value="Posterior">Posterior</option>
                        <option value="Posterolateral">Posterolateral</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Other diagnosis</Form.Label>
                      <Select
                        isMulti
                        value={ligamentInjuryOtherDiagnosisOptions.filter(option => formData.other.split(",").includes(option.value))}
                        name="other"
                        options={ligamentInjuryOtherDiagnosisOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "other", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOsteochondralLesion": {
      return (
        <div className="diagnosis-elbow-osteochondral-lesion">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteochondral Lesion
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Trochlea">Trochlea</option>
                        <option value="Coronoid">Coronoid</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Probable Cause</Form.Label>
                      <Select
                        isMulti
                        value={osteochondralLesionProbableCauseOptions.filter(option => formData.probableCause.split(",").includes(option.value))}
                        name="probableCause"
                        options={osteochondralLesionProbableCauseOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "probableCause", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Length of Leasion</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Width of Leasion</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Area of Leasion</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.area}
                        onChange={(e) => {onChangeHandler(arrayIndex, "area", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Depth of Leasion</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-1000}
                        max={1000}
                        value={formData.depth}
                        onChange={(e) => {onChangeHandler(arrayIndex, "depth", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.calcificTendonosis}
                        label="Calcific Tendonosis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "calcificTendonosis", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>ICRS Grade</Form.Label>
                      <Form.Select
                        value={formData.icrsGrade}
                        onChange={(e) => {onChangeHandler(arrayIndex, "icrsGrade", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Grade 1A">Grade 1A</option>
                        <option value="Grade 1B">Grade 1B</option>
                        <option value="Grade 2">Grade 2</option>
                        <option value="Grade 3A">Grade 3A</option>
                        <option value="Grade 3B">Grade 3B</option>
                        <option value="Grade 3C">Grade 3C</option>
                        <option value="Grade 3D">Grade 3D</option>
                        <option value="Grade 4A">Grade 4A</option>
                        <option value="Grade 4B">Grade 4B</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOsteoarthritis": {
      return (
        <div className="diagnosis-elbow-osteoarthritis">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteoarthritis
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Trochlea">Trochlea</option>
                        <option value="Coronoid">Coronoid</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Outerbridge grade</Form.Label>
                      <Form.Select
                        value={formData.grade}
                        onChange={(e) => {onChangeHandler(arrayIndex, "grade", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Grade I">Grade I</option>
                        <option value="Grade II">Grade II</option>
                        <option value="Grade III">Grade III</option>
                        <option value="Grade IV">Grade IV</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type of tendon injury</Form.Label>
                      <Select
                        isMulti
                        value={anteriorPosteriosOptions.filter(option => formData.osteophytes.split(",").includes(option.value))}
                        name="osteophytes"
                        options={anteriorPosteriosOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "osteophytes", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOtherInflammatoryArthritis": {
      return (
        <div className="diagnosis-elbow-other-inflammatory-arthritis">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Other Inflammatory Arthritis
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Rheumatoid">Rheumatoid</option>
                        <option value="Post-traumatic">Post-traumatic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowLooseBody": {
      return (
        <div className="diagnosis-elbow-loose-body">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Loose Body(s)
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Compartement</Form.Label>
                      <Select
                        isMulti
                        value={anteriorPosteriosOptions.filter(option => formData.compartement.split(",").includes(option.value))}
                        name="compartement"
                        options={anteriorPosteriosOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "compartement", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCapsuleSynovium": {
      return (
        <div className="diagnosis-elbow-capsule-synovium">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Capsule/Synovium
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Synovitis</Form.Label>
                      <Select
                        isMulti
                        value={synovitisOptions.filter(option => formData.synovitis.split(",").includes(option.value))}
                        name="synovitis"
                        options={synovitisOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "synovitis", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.capsularPlicaPosterolateralImpingement}
                        label="Capsular plica posterolateral impingement" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "capsularPlicaPosterolateralImpingement", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Capsular contracture (arthrofibrosis)</Form.Label>
                      <Select
                        isMulti
                        value={capsularContractureOptions.filter(option => formData.capsularContracture.split(",").includes(option.value))}
                        name="capsularContracture"
                        options={capsularContractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "capsularContracture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowNerve": {
      return (
        <div className="diagnosis-elbow-nerve">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Nerve
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Acute or Chronic</Form.Label>
                      <Form.Select
                        value={formData.acuteOrChronic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "acuteOrChronic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Acute (<2 weeks)">Acute (&lt;2 weeks)</option>
                        <option value="Subacute (2-6 weeks)">Subacute (2-6 weeks)</option>
                        <option value="Chronic (>6 weeks)">Chronic (&gt;6 weeks)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Radial tunnel syndrome">Radial tunnel syndrome</option>
                        <option value="Ulnar neuritis">Ulnar neuritis</option>
                        <option value="Ulnar nerve entrapment (cubital tunnel syndrome)">Ulnar nerve entrapment (cubital tunnel syndrome)</option>
                        <option value="Pronator syndrome">Pronator syndrome</option>
                        <option value="Lacertus syndrome">Lacertus syndrome</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowFracture": {
      return (
        <div className="diagnosis-elbow-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Intra-articular fracture</Form.Label>
                      <Select
                        isMulti
                        value={intraArticularFractureOptions.filter(option => formData.intraArticularFracture.split(",").includes(option.value))}
                        name="intraArticularFracture"
                        options={intraArticularFractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "intraArticularFracture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Avulsion fracture</Form.Label>
                      <Select
                        isMulti
                        value={avulsionFractureOptions.filter(option => formData.avulsionFracture.split(",").includes(option.value))}
                        name="avulsionFracture"
                        options={avulsionFractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "avulsionFracture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.distalHumerusFracture}
                        label="Distal humerus fracture" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "distalHumerusFracture", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.proximalBothBonesForearmFracture}
                        label="Proximal both-bones forearm fracture" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "proximalBothBonesForearmFracture", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Stress fracture</Form.Label>
                      <Select
                        isMulti
                        value={stressFractureOptions.filter(option => formData.stressFracture.split(",").includes(option.value))}
                        name="stressFracture"
                        options={stressFractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "stressFracture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Apophyseal Injuries</Form.Label>
                      <Select
                        isMulti
                        value={apophysealInjuryOptions.filter(option => formData.apophysealInjuries.split(",").includes(option.value))}
                        name="apophysealInjuries"
                        options={apophysealInjuryOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "apophysealInjuries", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Fracture Status</Form.Label>
                      <Form.Select
                        value={formData.fractureStatus}
                        onChange={(e) => {onChangeHandler(arrayIndex, "fractureStatus", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh fracture">Fresh fracture</option>
                        <option value="Malunion">Malunion</option>
                        <option value="Nonunion">Nonunion</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "olecranonSpur": {
      return (
        <div className="diagnosis-olecranon-spur">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Olecranon Spur
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Select
                        isMulti
                        value={olecranonSpurLocationOptions.filter(option => formData.location.split(",").includes(option.value))}
                        name="location"
                        options={olecranonSpurLocationOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "location", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOther": {
      return (
        <div className="diagnosis-other">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Other
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.rheumatoidArthritis}
                        label="Rheumatoid arthritis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "rheumatoidArthritis", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.septicArthritis}
                        label="Septic arthritis" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "septicArthritis", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Olecranon bursitis</Form.Label>
                      <Form.Select
                        value={formData.olecranonBursitis}
                        onChange={(e) => {onChangeHandler(arrayIndex, "olecranonBursitis", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Infected ">Infected </option>
                        <option value="Inflammatory">Inflammatory</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default Diagnosis